.InfoBoxCommon {
  display: relative;
  background-color: white;
  align-items: center;
  /* height: 100px; */
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: 30px;
  text-align: center;
}

.InfoBoxCommon2 {
  padding-right: 35px;
  margin-right: 35px;
  border-right: 2px solid #d3d3d3;
}

.InfoBoxCommon3 {
  padding-top: 50px;
  padding-left: 150px;
  border-bottom: 1px solid #d3d3d3;
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr;
  background-color: white;
  align-items: center;
}

.InfoBoxMonth {
  padding-left: 150px;
  border-bottom: 1px solid #d3d3d3;
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr;
  background-color: white;
  align-items: center;
}
