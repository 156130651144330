.Button {
  background: #ffd600;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 10px 25px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin: -10px 5px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.Success {
  color: #5c9210;
}

button.btn {
  overflow: hidden;
  position: relative;
}

Button:hover {
  color: black;
}
