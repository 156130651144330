* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Jua", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  font-family: "Jua";
}

input:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 24px;
}
h2 {
  font-size: 16px;
}

h3 {
  font-size: 12px;
}

h4 {
  font-size: 10px;
}

p {
  font-size: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #57ac87;
  transition: background-color 5000s ease-in-out 0s;
}

@media (min-width: 600px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 12px;
  }

  p {
    font-size: 10px;
  }
}
