.Modal {
  position: absolute;
  z-index: 500;
  background-color: white;
  width: 80%;
  border-top: 2px solid #ffd600;
  border-bottom: 2px solid #ffd600;
  border-left: 2px solid #ffd600;
  border-right: 2px solid #ffd600;
  left: 150px;
  top: 150px;
  box-sizing: border-box;
  transition: all 0.1s ease-out;
  text-align: center;
  width: 250px;
}

@media (min-width: 800px) {
  .Modal {
    width: 350px;
  }
}
