.html {
  height: 100%;
}

.body {
  /* width: 50%; */
  height: 100%;
  margin: 0;
  position: relative;
}

#calendar {
  height: 99%;
  box-sizing: border-box;
  padding: 0.5%;
}

.horizontalGutter {
  margin-left: 0.7%;
  margin-right: 0.7%;
}

.verticalGutter {
  margin-top: 0.5%;
}

.center {
  margin: auto;
  width: 80%;
  max-width: 1800px;
  min-width: 1500px;
  top: 50%;
  left: 50%;
  font-size: 20px;
}
div.loader {
  font-size: 100px;
}

.Button {
  background-color: #4b77d4;
  outline: none;
  cursor: pointer;
  border: 1px solid #1cc5f8;
  color: #ffffff;
  font-family: "NanumGothicBold";
  font-size: 1.2em;
  padding: 15px 30px;
  box-shadow: 2px 2px 2px #1b00b6;
}
