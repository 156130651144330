.MenuSummary {
  display: table;
  z-index: 2;
  background-color: white;
  position: relative;
  width: 500px;
  margin-left: -40px;
  padding: 20px;
  transition: all 0.1s ease-out;
  text-align: right;
  border: 0.1em solid #ffd600;
  min-height: 500px;
  overflow: overlay;
  box-sizing: border-box;
}

.week {
  top: 10px;
}

.month {
  top: 30px;
  left: -10px;
}
