.MenuTable {
  background-color: #f5f5f5;
  position: relative;
  left: 20px;
  height: 100%;
  min-width: 1600px;
  margin-top: 0px;
}
.TableAndSummary_week {
  display: grid;
  grid-template-columns: 1fr 600px;
  width: 2000px;
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 45px;
}
.TableAndSummary_month {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 600px;
  margin-top: 10px;
  margin-left: -100px;
}
