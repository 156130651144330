/*
https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
*/
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin: 20px;
  padding: 0.625rem 1.25rem;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label i,
.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

.inputfile + label {
  color: #004ca8;
  border: 2px solid currentColor;
}

.inputfile:focus + label,
.inputfile.has-focus + label,
.inputfile + label:hover {
  color: #599eff;
}
