.html {
  height: 100%;
}

.body {
  height: 100%;
  margin: 0;
  position: relative;
}

#calendar {
  height: 99%;
  box-sizing: border-box;
  padding: 0.5%;
}

.calendar__start {
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  height: 270px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 0px;
  background-color: white;
}

.calendar__start:hover,
.calendar__start:active {
  background-color: white;
  border: 4px solid blue;
  color: black;
}

.calendar__day2 {
  border-radius: 0px;
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  height: 270px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 0px;
  background-color: white;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
}

.calendar__day2:hover,
.calendar__day2:active {
  background-color: white;
  border: 4px solid blue;
  color: black;
}

.calendar_week_day {
  border-top: 4px solid #ffd600;
  border-radius: 0px;
  display: inline-block;
  vertical-align: bottom;
  width: 180px;
  height: 270px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 0px;
  background-color: white;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
  font-family: NanumGothicBold;
  line-height: 25px;
  text-align: center;
  font-size: 16px;
}

.calendar_week_day:hover,
.calendar_week_day:active {
  background-color: #ffd600;
  color: black;
}

.calendar__day3 {
  border-top: 4px solid #ffd600;
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  height: 270px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 0px;
  background-color: white;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
  max-width: 220px;
}

.calendar__day3:hover,
.calendar__day3:active {
  background-color: #ffd600;
  /* border: 4px solid blue; */
  color: black;
}

.calendar__day3_month {
  border-top: 4px solid #ffd600;
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  box-sizing: border-box;
  padding: 10px 15px 15px 15px;
  margin-top: 0px;
  background-color: white;
  margin-left: 1.1%;
  margin-right: 1.1%;
  margin-bottom: 0%;
  width: 200px;
  height: 270px;
}

.calendar__day3_month:hover,
.calendar__day3_month:active {
  background-color: #ffd600;
  /* border: 4px solid blue; */
  color: black;
}

.calendar__day4 {
  border-top: 4px solid #ffd600;
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  height: 270px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 0px;
  background-color: white;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
  max-width: 220px;
  margin-top: -10px;
  text-align: center;
  font-size: 18px;
  color: #717a80;
  font-family: NanumGothicBold;
  line-height: 25px;
}

.calendar__day4_month {
  border-top: 4px solid #ffd600;
  display: inline-block;
  vertical-align: bottom;
  width: calc(94% / 6);
  box-sizing: border-box;
  padding: 10px 0px 15px 0px;
  margin-top: 0px;
  background-color: white;
  margin-left: 1.1%;
  margin-right: 1.1%;
  text-align: center;
  font-size: 16px;
  color: #717a80;
  font-family: NanumGothicBold;
  line-height: 25px;
  width: 200px;
  height: 270px;
}

.calendar__day4:hover,
.calendar__day4:active {
  background-color: #ffd600;
  /* border: 4px solid blue; */
  color: black;
}

.calendar__day4_month:hover,
.calendar__day4_month:active {
  background-color: #ffd600;
  /* border: 4px solid blue; */
  color: black;
}

.center {
  margin: auto;
  width: 80%;
  max-width: 1800px;
  min-width: 1500px;
  top: 50%;
  left: 50%;
  font-size: 20px;
}
div.loader {
  font-size: 100px;
}

.Button {
  background-color: white;
  outline: none;
  cursor: pointer;
  border: 1px solid #1cc5f8;
  color: #ffffff;
  font-family: inherit;
  font-size: 1.2em;
  padding: 15px 30px;
  box-shadow: 2px 2px 2px #1b00b6;
}

.EmptyColumn {
  display: inline-block;
  width: calc(94% / 11);
  height: 150px;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: white;
  opacity: 0;
  margin-right: 0.3%;
  font-size: 23px;
  padding: 100px 0;
}
