/* Post at: https://superdevresources.com/3d-css-button/ */

.position {
  background-color: #ffd600;
  height: 4em;
  position: relative;
  left: 4em;
  right: 4em;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.position_month {
  background-color: #ffd600;
  width: 1280px;
  height: 65px;
  margin-left: 350px;
}

div.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn {
  width: 8em;
  background: white;
  height: 2em;
  color: grey;
  left: 4em;
  right: 4em;
  border: 0px;
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin: 0.5em 0.5em;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

button.btn {
  overflow: hidden;
  position: relative;
}

button.btn:hover {
  color: black;
}

button.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #fff;
}

button.btn:hover:after,
button.btn:active:after {
  height: 100%;
}
