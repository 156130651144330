.WeekColumn {
  border-top: 4px solid #d3d3d3;
  display: inline-block;
  width: 150px;
  height: 270px;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: #ffd600;
  margin-right: 0.3%;
  font-size: 23px;
  padding: 100px 0;
}

.WeekBox {
  border-top: 6px solid #ffd600;
  display: inline-block;
  width: 150px;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: white;
  margin-right: 0.3%;
  padding: 0px 0;
  height: 150px;
  font-size: 33px;
  padding-right: 90px;
  padding-top: 50px;
  padding-bottom: 0px;
  font-size: 60px;
  color: black;
}

.AddBoxOutlinedIcon {
  font-size: 10rem;
}

.calendar__top2 {
  border-radius: 0px;
  display: inline-block;
  vertical-align: bottom;
  width: 230px;
  height: 70px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 10px;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
  font-size: 40px;
  padding: 0px;
  padding-top: 10px;
  padding-right: 160px;
  line-height: 30px;
}

.calendar__top2:active {
  border: 0px solid blue;
  color: black;
}

.general {
  position: relative;
  left: 55px;
  margin-bottom: -10px;
  padding-right: 100px;
  height: 60px;
  font-size: 20px;
  text-align: center;
  width: 50px;
  background: #ffd600;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.event {
  height: 80px;
  font-size: 33px;
  width: 50px;
  background: blue;
}

.calendar_week_day2 {
  border-top: 4px solid #ffd600;
  border-radius: 0px;
  display: inline-block;
  vertical-align: bottom;
  width: 230px;
  height: 350px;
  box-sizing: border-box;
  margin-top: 0px;
  background-color: white;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 0%;
  font-family: NanumGothic;
  font-size: 20px;
  font-weight: 580;
  color: grey;
  line-height: 25px;
  text-align: center;
  line-height: 1.6;
}

.calendar_week_day2:hover,
.calendar_week_day2:active {
  background-color: #ffd600;
  color: black;
}
